import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Timeline from "./pages/Timeline";
import Registry from "./pages/Registry";
import Travel from "./pages/Travel";
import OurStory from "./pages/OurStory";
import Rsvp from "./pages/Rsvp";
import Photos from "./pages/Photos";
import Faqs from "./pages/FAQs";
import PasswordPage from "./pages/Password";
import RsvpSuccess from "./pages/RsvpSuccess";
import { Amplify } from "aws-amplify";
import config from "./aws-exports";

Amplify.configure(config);

export default function Root() {
  function isUserValidated() {
    return sessionStorage.getItem("isValidated") === "true";
  }

  function AppOutlet() {
    return isUserValidated() ? <App /> : <Navigate to="/login" />;
  }

  function TimelineOutlet() {
    return isUserValidated() ? <Timeline /> : <Navigate to="/login" />;
  }

  function OurStoryOutlet() {
    return isUserValidated() ? <OurStory /> : <Navigate to="/login" />;
  }

  function TravelOutlet() {
    return isUserValidated() ? <Travel /> : <Navigate to="/login" />;
  }

  function PhotosOutlet() {
    return isUserValidated() ? <Photos /> : <Navigate to="/login" />;
  }

  function RegistryOutlet() {
    return isUserValidated() ? <Registry /> : <Navigate to="/login" />;
  }

  function RsvpOutlet() {
    return isUserValidated() ? <Rsvp /> : <Navigate to="/login" />;
  }

  function FaqsOutlet() {
    return isUserValidated() ? <Faqs /> : <Navigate to="/login" />;
  }

  function SuccessOutlet() {
    return isUserValidated() ? <RsvpSuccess /> : <Navigate to="/login" />;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<AppOutlet />} />
        <Route path="/timeline" element={<TimelineOutlet />} />
        <Route path="/ourstory" element={<OurStoryOutlet />} />
        <Route path="/travel" element={<TravelOutlet />} />
        <Route path="/photos" element={<PhotosOutlet />} />
        <Route path="/registry" element={<RegistryOutlet />} />
        <Route path="/rsvp" element={<RsvpOutlet />} />
        <Route path="/faqs" element={<FaqsOutlet />} />
        <Route path="/success" element={<SuccessOutlet />} />
        <Route path="/login" element={<PasswordPage />} />
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
