import "./NavBar.css";

function NavBar() {
  return (
    <body className="NavBar">
      <a class="NavBar-text" href="./">
        HOME
      </a>
      <a class="NavBar-text" href="./ourstory">
        OUR STORY
      </a>
      <a class="NavBar-text" href="./timeline">
        TIMELINE
      </a>
      <a class="NavBar-text" href="./travel">
        TRAVEL
      </a>
      <a class="NavBar-text" href="./photos">
        GALLERY
      </a>
      <a class="NavBar-text" href="./registry">
        REGISTRY
      </a>
      <a class="NavBar-text" href="./faqs">
        FAQs
      </a>
      <a class="NavBar-text" href="./rsvp">
        RSVP
      </a>
    </body>
  );
}

export default NavBar;
