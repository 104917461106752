import "./Footer.css";

function Footer() {
  return (
    <div className="Footer">
      <body className="Footer_body">
        <p>Thank you for joining us on our special day!</p>
        <p>
          For any questions, please email us at{" "}
          <a
            href="mailto:graceanddouglas@gmail.com"
            target="_blank"
            className="Footer_email"
          >
            graceanddouglas@gmail.com
          </a>{" "}
        </p>
      </body>
      <body className="Footer_Monogram">
        <p>
          <b>G | D</b>
        </p>
      </body>
      <body className="Footer_Date">09.21.24</body>
      {/* <body className="Footer_Date">
                    <p><b>09.21.24</b></p>
                </body> */}
    </div>
  );
}

export default Footer;
