import "./Registry.css";
import NavBar from "../components/NavBar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import TestPhoto from "../images/grace+douglas-15.jpg";

function Registry() {
  return (
    <div className="Registry">
      <Header />
      <NavBar />
      {/* <h1 className="Title-style">REGISTRY</h1>
      <img src={Divider} width="20%" /> */}
      <img src={TestPhoto} className="registry_photo" />
      <h1 className="registry_h1">
        We are so grateful to be able to share our wedding celebration with our
        friends and family, and your presence is truly the most meaningful gift
        we could ask for.<br></br>
        <br></br>But if you would like to gift us something, we would graciously
        appreciate contributions to our first home down payment or honeymoon
        fund. Please check out the link below to contribute to our newlywed
        funds!<br></br>
        <br></br>
        <a
          className="registry_h1_link"
          href="https://www.zola.com/registry/graceanddouglasseptember21"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.zola.com/registry/graceanddouglasseptember21
        </a>
        <br></br>
        <br></br>There will also be a card box at the venue if you would like to
        gift us a card. Please make sure to write your name on the card so we
        know who to thank after the wedding!
      </h1>
      {/* <h2 className="registry_h1">
        But if you would like to gift us something, we would gladly appreciate
        contributions to our first home down payment or honeymoon fund. Please
        feel free to venmo Grace at @graced95 and there will also be a card box
        present at the wedding. Please make sure to mark your name on the card
        so we know who to thank after the wedding!
      </h2> */}
      <br></br>
      <h2 className="registry_h2">
        Thank you so much!<br></br> - G & D
      </h2>

      {/* <Box alignItems="center" justifyContent="center" display="flex" pb={4}>
        <RegistryCard />

        <RegistryCard2 />
      </Box> */}

      <Footer />
    </div>
  );
}

export default Registry;
