import "./FAQs.css";
import NavBar from "../components/NavBar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import HomeIcon from "@mui/icons-material/Home";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import Divider from "../images/Divider.png";
import TestPhoto from "../images/grace+douglas-7.jpg";

function FAQs() {
  return (
    <div className="FAQ">
      <Header />
      <NavBar />
      {/* <h1 className="Title-style">FREQUENTLY ASKED QUESTIONS (FAQs)</h1>
      <img src={Divider} width="20%" /> */}
      <img src={TestPhoto} className="faq_photo" />
      <body></body>
      <body>
        <h1 className="FAQ_h1">WHAT IS THE DRESS CODE?</h1>
        <h2 className="FAQ_h2">
          Formal Cocktail Attire.<br></br>From theknot.com:<br></br> "Formal
          cocktail attire is a dress code that leans more on the formal side.
          Unlike a black-tie dress code, an evening gown or tuxedo is not
          necessary for a formal cocktail event. You might, however, consider
          opting for dressier cocktail attire, meaning a full-length dress as
          opposed to a mini or midi-length one. For men, we recommend a suit and
          tie in a classic color like black or navy blue."
        </h2>
        <h1 className="FAQ_h1">
          <p>WHERE SHOULD I RSVP?</p>
        </h1>
        <h2 className="FAQ_h2">
          To RSVP, click the "RSVP" tab and type in your name (first and last
          name) in the text box to RSVP for you and your party. Please RSVP by
          Saturday, August 17th. If you and your party are unable to attend, we
          would appreciate you letting us know at the soonest opportunity.
        </h2>
        <h1 className="FAQ_h1">
          <p>CAN I BRING A PLUS ONE?</p>
        </h1>
        <h2 className="FAQ_h2">
          We unfortunately cannot accommodate every plus one unless we
          specfically indicated their name in your party. Please refer to the
          RSVP tab and type in your name to check who is in your party. We
          appreciate your understanding!
        </h2>
        <h1 className="FAQ_h1">
          <p>ARE KIDS WELCOME?</p>
        </h1>
        <h2 className="FAQ_h2">
          Similarly to plus ones, we unfortunately cannot accommodate everyone's
          children at our wedding. Please refer to the RSVP tab and type in your
          name to see if we are able to accommodate your children. If we are
          unable to invite your children, we appreciate you making arrangements
          ahead of time and hope that you will see this as an opportunity to let
          loose and party with us! We appreciate your understanding!
        </h2>
        <h1 className="FAQ_h1">
          <p>WHAT TIME SHOULD I GET TO THE VENUE FOR THE CEREMONY?</p>
        </h1>
        <h2 className="FAQ_h2">
          4:30-4:45 PM. Please note that the ceremony will start promptly at
          5:00 PM.
        </h2>
        <h1 className="FAQ_h1">
          <p>WHAT ARE THE AFTER-PARTY DETAILS?</p>
        </h1>
        <h2 className="FAQ_h2">
          This will be a informal event after the reception to chill with
          friends and family and continue our wedding festivities! There will be
          drinks and snacks provided but everything will be self-serve. There
          are games available such as putt-putt, cornhole, bocce ball, and
          drinking games :). This event will take place mainly outdoors. Please
          bring a jacket/sweater as it will get chilly later at night!
        </h2>
        <h1 className="FAQ_h1">
          <p>WILL THERE BE A REGISTRY?</p>
        </h1>
        <h2 className="FAQ_h2">
          Please do not feel obligated to gift us anything, but if you would
          like to gift us something, we would appreciate any contributions to
          our newlywed funds. More information is provided in the "Registry" tab
          on the website.
        </h2>
        <h1 className="FAQ_h1">
          <p>ARE THERE ANY HOTEL BLOCKS AVAILABLE?</p>
        </h1>
        <h2 className="FAQ_h2">
          Yes, we reserved a block at the Holiday Inn Express & Suites Warrenton
          at a discounted price. Please reserve a room by August 20, 2024 to
          receive the discounted price. More details provided in the "Travel"
          tab.
        </h2>
        <h1 className="FAQ_h1">
          <p>
            WILL THE CEREMONY, COCKTAIL HOUR, AND RECEPTION TAKE PLACE INDOORS
            OR OUTDOORS?
          </p>
        </h1>
        <h2 className="FAQ_h2">
          The ceremony and cocktail hour will take place outdoors, weather
          permitting. The reception will take place indoors. If we experience
          inclement weather the day of the wedding, the ceremony and cocktail
          hour will be held indoors.
        </h2>
        <h1 className="FAQ_h1">
          <p>
            WILL THERE BE TRANSPORTATION FROM THE VENUE TO THE NEARBY HOTELS?
          </p>
        </h1>
        <h2 className="FAQ_h2">
          Unfortunately, there will not be a shuttle from the venue to the
          hotels located in Warrenton, VA. Please plan accordingly and we offer
          the after party as a place to rest up before you and your party are
          able to drive for the night. The after party is only a 3 minute walk
          from the reception!
        </h2>
      </body>
      <br></br>
      <Footer />
    </div>
  );
}

export default FAQs;
