import "./Header.css";

function Header() {
  return (
    <div className="Header">
      <h1 class="HeaderMain">
        {/* <p>SEPTEMBER 21, 2024</p> */}
        <p>GRACE & DOUGLAS</p>
        {/* <br></br>
        <p className="Headerbelow">SEPTEMBER 21, 2024</p> */}
        {/* <p>WARRENTON, VA</p> */}
      </h1>
      <body></body>
      {/* <h2 className="Headerbelow">
        {" "}
        <p>SEPTEMBER 21, 2024</p>
      </h2> */}
    </div>
  );
}

export default Header;
