import "./RsvpSuccess.css";
import NavBar from "../components/NavBar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import TestPhoto from "../images/grace+douglas-13.jpg";
import * as React from "react";
import Stack from "@mui/material/Stack";
import "../pages/Rsvp.css";
import { post } from "aws-amplify/api";
import FormHelperText from "@mui/material/FormHelperText";
import {
  FormContainer,
  SelectElement,
  TextFieldElement,
} from "react-hook-form-mui";
import zip from "lodash/zip";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { AddToCalendarButton } from "add-to-calendar-button-react";

const fontFormat1 = {
  fontFamily: "didot, serif",
  color: "#6082b6",
  fontWeight: "bold",
  fontSize: "calc(3px + 3vmin)",
  fontStyle: "none",
  paddingBottom: "10px",
};
const fontFormat2 = {
  fontFamily: "didot, serif",
  color: "#6082b6",
  // fontWeight: "bold",
  fontSize: "calc(3px + 2.5vmin)",
  fontStyle: "none",
  padding: "8px",
};

function RsvpSuccess() {
  let successTable;
  let currentWeddingRsvp = [];
  let currentAfterPartyRsvp = [];
  let currentDietaryNotes = [];

  let uniqueKeyString = "";

  const { state } = useLocation();
  console.log("state check:", state);
  let party = state.party;
  console.log("check party:", party);

  if (party && party.length > 0) {
    uniqueKeyString += party[0].fullName;
    successTable = [];

    party.forEach((partyMember) => {
      const pastResponseWedding = partyMember ? partyMember.weddingRsvp : "";
      console.log("each member in [party table] loop: ", partyMember);
      console.log("test:", partyMember.weddingRsvp);
      currentWeddingRsvp.push(
        partyMember.weddingRsvp ? partyMember.weddingRsvp : ""
      );
      currentAfterPartyRsvp.push(
        partyMember.afterPartyRsvp ? partyMember.afterPartyRsvp : ""
      );
      currentDietaryNotes.push(
        partyMember.dietaryRestrictions ? partyMember.dietaryRestrictions : ""
      );
      successTable.push(
        // create a stack with responses.
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={0.5}
          pb={5}
        >
          <FormHelperText sx={fontFormat1}>
            {partyMember.fullName}
          </FormHelperText>
          <FormHelperText>
            Will this guest attend the Wedding and Ceremony?
          </FormHelperText>
          <FormHelperText sx={fontFormat2}>
            {partyMember.weddingRsvp}
          </FormHelperText>
          <FormHelperText>
            Will this guest attend the After Party?
          </FormHelperText>
          <FormHelperText sx={fontFormat2}>
            {partyMember.afterPartyRsvp}
          </FormHelperText>
          <FormHelperText>
            Does this guest have any dietary restrictions?
          </FormHelperText>
          <FormHelperText sx={fontFormat2}>
            {partyMember.dietaryRestrictions}
          </FormHelperText>
        </Stack>
      );
    });
  }

  console.log(
    "Rob, checking:",
    currentWeddingRsvp,
    currentAfterPartyRsvp,
    currentDietaryNotes
  );

  return (
    <div className="Travel">
      <Header />
      <NavBar />
      <body className="Success">
        Your party has successfully RSVP'd. If you need to edit your response,
        please return to the "RSVP" page. Thank you!
        {/* <AddToCalendarButton
          name="Grace and Douglas's Wedding"
          startDate="2024-09-21"
          options={["Apple", "Google", "Yahoo", "iCal"]}
          timeZone="America/New_York"
          className="Calendar"
        ></AddToCalendarButton> */}
      </body>

      <body className="table_h2">{successTable}</body>
      <br></br>
      {/* // {submitButton} */}
      {/* </FormContainer> */}
      <Footer />
    </div>
  );
}

export default RsvpSuccess;
