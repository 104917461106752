import "./Password.css";
import Divider from "../images/Divider.png";
import {
  FormContainer,
  SelectElement,
  TextFieldElement,
  PasswordElement,
} from "react-hook-form-mui";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Alert from "@mui/material/Alert";

function PasswordPage() {
  const [isError, setError] = useState(false);
  const navigate = useNavigate();
  //Error use state
  //let error
  let errorMessage;

  if (isError) {
    errorMessage = (
      <Alert
        severity="error"
        sx={{
          // width: "30%",
          fontFamily: "didot, serif",
          // textAlign: "center",
          // alignItems: "center",
          //find a way to center this
        }}
      >
        Password incorrect. Please try again.
      </Alert>
    );
  }

  return (
    <div className="Password">
      <FormContainer
        onSuccess={(data) => {
          console.log("onSuccess data row:", data);
          const password = "mochipoki";
          const errorCheck = false;
          if (data.password === password) {
            sessionStorage.setItem("isValidated", true);
            navigate("/");
          } else {
            setError(true);
          }

          //reference error here.
        }}
      >
        <h1 className="Password_h1">
          WELCOME! <br></br>
          PLEASE ENTER THE PASSWORD TO ENTER THIS SITE:
        </h1>
        <Box>
          <PasswordElement
            margin={"dense"}
            label={"Password"}
            required
            name={"password"}
          />
        </Box>
        <h2 className="error_h2">{errorMessage}</h2>
        <br></br>
        <Button
          type={"submit"}
          variant="outlined"
          className="button"
          size="medium"
        >
          Submit
        </Button>

        <h2 className="Password_h2">
          If you do not know the password, please refer to your invitation or
          reach out to Grace or Douglas.
        </h2>
      </FormContainer>
    </div>
  );
}

export default PasswordPage;
