import "./Travel.css";
import NavBar from "../components/NavBar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import HomeIcon from "@mui/icons-material/Home";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import Divider from "../images/Divider.png";
import TestPhoto from "../images/grace+douglas-13.jpg";

function Travel() {
  return (
    <div className="Travel">
      <Header />
      <NavBar />
      {/* <h1 className="Title-style">TRAVEL</h1>
      <img src={Divider} width="20%" /> */}

      <body>
        <img src={TestPhoto} className="travel_picture" />
      </body>

      <body>
        <h1 className="Accommodations_h1">
          <AirplanemodeActiveIcon sx={{ fontSize: 50, pb: 3 }} />

          <p>GETTING TO THE VENUE</p>
        </h1>
        <h3 className="Accommodations_h2">
          The closest airports from the venue are Dulles International Airport
          (IAD) and Reagan National Airport (DCA).
          <p>
            From the listed airports, the best method of transportation to get
            to the venue is by car.
          </p>
          <p>
            We recommend taking I-66. Non-express lanes are free on weekends.
          </p>
        </h3>

        <h1 className="Accommodations_h1">
          <HomeIcon sx={{ fontSize: 50, pb: 3 }} />
          <p>ACCOMMODATIONS</p>
        </h1>
        <h2 className="Accommodations_h2">
          We recommend that you book your accommodations as soon as you can as
          some hotels in the area are fully booked already!
        </h2>
        <h2 className="Accommodations_h2">
          <a
            class="Accomodations_title"
            href="https://www.ihg.com/holidayinnexpress/hotels/us/en/warrenton/wrvva/hoteldetail?cm_mmc=GoogleMaps-_-EX-_-US-_-WRVVA"
            target="_blank"
            rel="noopener noreferrer"
          >
            Holiday Inn Express & Suites Warrenton
          </a>
          <h3 className="Accommodations_h3">410 Holiday Court</h3>
          <h3 className="Accommodations_h3">Warrenton, VA 20186</h3>
          <br></br>
          <h3 className="Accommodations_h3">
            8.3 miles/14 minutes away from Poplar Springs Manor
          </h3>
          <h3 className="Accommodations_h3">
            <p>
              We have reserved a block of rooms at a discounted price ($184 with
              taxes/fees). Please use the link below to reserve a room at this
              rate.
            </p>
          </h3>
          <h3 className="Accommodations_h3">
            Please book by August 20th, 2024.
          </h3>
          <br></br>
          <h3 className="Accommodations_h3">
            <a
              class="Accommodations_h3"
              href="https://www.ihg.com/holidayinnexpress/hotels/us/en/find-hotels/select-roomrate?qDest=410%20Holiday%20Court,%20Warrenton,%20VA,%20US&qPt=CASH&qCiD=21&qCoD=22&qCiMy=082024&qCoMy=082024&qAdlt=1&qChld=0&qRms=1&qIta=99801505&qRtP=6CBARC&qGrpCd=DTW&qSlH=wrvva&srb_u=1&qSrt=sBR&qBrs=6c.hi.ex.sb.ul.ic.cp.cw.in.vn.cv.rs.ki.ki.ma.sp.va.sp.re.vx.nd.sx.we.lx.rn.sn.sn.sn.sn.sn.nu&qWch=0&qSmP=0&qRad=30&qRdU=mi&setPMCookies=false&qpMbw=0&qErm=false&qpMn=0&qRmFltr=&qChAge="
              target="_blank"
              rel="noopener noreferrer"
            >
              Holiday Inn Wedding Block Link
            </a>
          </h3>
        </h2>
        <br></br>
        <h2 className="Accommodations_h2">
          <a
            class="Accomodations_title"
            href="https://www.hilton.com/en/hotels/waswrhx-hampton-warrenton/?SEO_id=GMB-AMER-HX-WASWRHX&y_source=1_MjA4NDM1NS03MTUtbG9jYXRpb24ud2Vic2l0ZQ%3D%3D"
            target="_blank"
            rel="noopener noreferrer"
          >
            Hampton Inn Warrenton
          </a>

          <h3 className="Accommodations_h3">501 Blackwell Road</h3>
          <h3 className="Accommodations_h3">Warrenton, VA 20186</h3>
          <br></br>
          <h3 className="Accommodations_h3">
            9.0 miles/14 minutes away from Poplar Springs Manor
          </h3>
          <h3 className="Accommodations_h3">
            <p>
              Although we did not have a reserved block at this hotel, this is a
              good hotel alternative close to the venue!
            </p>
          </h3>
        </h2>
        <br></br>
        <h2 className="Accommodations_h2">
          <a
            class="Accomodations_title"
            href="https://be.synxis.com/?adult=2&arrive=2024-09-21&chain=22582&child=0&config=meta&currency=USD&depart=2024-09-22&dsclid=62610767875166208&filter=META&hotel=45491&level=hotel&locale=en-US&rooms=1&src=goog_organic&start=availresults&utm_campaign=google-hpa-45491&utm_content=mapresults_21-09-2024_1_45491_US_desktop_unknown_unknown_unknown_organic&utm_medium=channel_connect&utm_source=google-hpa"
            target="_blank"
            rel="noopener noreferrer"
          >
            Airlie
          </a>

          <h3 className="Accommodations_h3">6809 Airlie Road</h3>
          <h3 className="Accommodations_h3">Warrenton, VA 20187</h3>
          <br></br>
          <h3 className="Accommodations_h3">
            11.5 miles/17 minutes away from Poplar Springs Manor
          </h3>
          <h3 className="Accommodations_h3">
            <p>
              Although we did not have a reserved block at this hotel, this is a
              good hotel alternative close to the venue! This hotel is located
              on a nice large property that offers tons of fun activities on
              site!
            </p>
          </h3>
        </h2>
        <h1 className="Accommodations_h1">
          <DirectionsCarIcon sx={{ fontSize: 50, pb: 3 }} /> <p>PARKING</p>
        </h1>
        <h2 className="Accommodations_h2">
          There is ample parking at the venue.
          <p>If you believe you need to leave your car overnight,</p>
          <p>you can leave your car at the venue until 11 AM the next day.</p>
        </h2>
      </body>
      <br></br>
      <Footer />
    </div>
  );
}

export default Travel;
