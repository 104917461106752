import logo from "./logo.svg";
import "./App.css";
import NavBar from "./components/NavBar";
import Header from "./components/Header";
import Footer from "./components/Footer";
import TestPhoto from "./images/grace+douglas-14.jpg";
import TestPhoto1 from "./images/grace+douglas-31.jpg";
import TestPhoto2 from "./images/grace+douglas-167.jpg";
import CelebrationIcon from "@mui/icons-material/Celebration";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import React, { useState, useEffect } from "react";
import { post } from "aws-amplify/api";

function App() {
  async function fetch_user(userName) {
    var request = {
      requestType: "fetchUser",
      userName: userName,
    };
    try {
      const restOperation = post({
        apiName: "backend",
        path: "/rsvp",
        options: {
          body: request,
        },
      });
      const { body } = await restOperation.response;
      const user_json = await body.json();
      console.log("POST call succeeded");
      console.log(user_json);
      return user_json;
    } catch (e) {
      console.log("POST call failed: ", e);
    }
  }

  const [userData, setUserData] = useState(null);

  useEffect(() => {
    async function fetchUserData() {
      try {
        const userJson = await fetch_user("Grace Dai");
        setUserData(userJson);
      } catch (error) {
        console.error("Failed to fetch user data: ", error);
      }
    }

    fetchUserData();
  }, ["grace"]);

  return (
    <div className="App">
      <Header />
      <NavBar />
      <h1>
        <img
          src={TestPhoto}
          width="25%"
          className="Photo_main"
          loading="eager"
        />
        <img
          src={TestPhoto1}
          width="44%"
          className="Photo_main"
          loading="eager"
        />
        <img src={TestPhoto2} width="25%" loading="eager" />
      </h1>
      <body className="App-body">
        {/*<p>GRACE DAI</p>
        <p>&</p>
        <p>DOUGLAS TONG</p>*/}

        <h1 className="details-h1">THE DETAILS</h1>
        {/* <br></br> */}
        <FavoriteBorderIcon sx={{ fontSize: 40 }} />
        <p class="App-bodytext2">CEREMONY AND RECEPTION</p>
        <p class="App-bodytext">September 21, 2024</p>
        <p class="App-bodytext">Poplar Springs Manor</p>
        <p class="App-bodytext">
          <a
            class="App-bodytext"
            href="https://www.google.com/maps/place/Poplar+Springs+Manor/@38.6524307,-77.7165254,17z/data=!3m1!4b1!4m6!3m5!1s0x89b68909c5834f1b:0x4e5ebaf9d82edd53!8m2!3d38.6524265!4d-77.7139505!16s%2Fg%2F11g2_1yt5w?entry=ttu"
            target="_blank"
            rel="noopener noreferrer"
          >
            5025 Casanova Road Warrenton, VA 20187
          </a>
        </p>
        <p class="App-bodytext">5:00 PM</p>
        <p class="App-bodytext">Formal Cocktail Attire</p>
        <br></br>

        <CelebrationIcon sx={{ fontSize: 40 }} />
        <p class="App-bodytext2">AFTER PARTY</p>
        <p class="App-bodytext">September 21, 2024</p>
        <p class="App-bodytext">The Inn at Poplar Springs Manor</p>
        <p class="App-bodytext">
          <a
            class="App-bodytext"
            href="https://www.google.com/maps/place/5023+Casanova+Rd,+Midland,+VA+22728/@38.6511185,-77.7144645,19z/data=!4m14!1m7!3m6!1s0x89b68909c5834f1b:0x4e5ebaf9d82edd53!2sPoplar+Springs+Manor!8m2!3d38.6524265!4d-77.7139505!16s%2Fg%2F11g2_1yt5w!3m5!1s0x89b688387e7a6f63:0x28f86dc49cb10354!8m2!3d38.651021!4d-77.7135927!16s%2Fg%2F11rwm1qp9c?entry=ttu"
            target="_blank"
            rel="noopener noreferrer"
          >
            5023 Casanova Road Warrenton, VA 20187
          </a>
        </p>
        <p class="App-bodytext">11:00 PM</p>
        <p class="App-bodytext">Three minute walk from the reception site</p>
      </body>
      <Footer />
    </div>
  );
}

export default App;
