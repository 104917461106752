import * as React from "react";
import { useState } from "react";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import "./Rsvp.css";
import NavBar from "../components/NavBar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Divider from "../images/Divider.png";
import CircularProgress from "@mui/material/CircularProgress";
import { post } from "aws-amplify/api";
import Alert from "@mui/material/Alert";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import Typography from "@mui/material/Typography";
import TestPhoto from "../images/grace+douglas-6.jpg";
import {
  FormContainer,
  SelectElement,
  TextFieldElement,
} from "react-hook-form-mui";
import zip from "lodash/zip";
import RsvpForm from "../components/RsvpForm";
import RsvpSuccess from "./RsvpSuccess";
import { useNavigate } from "react-router-dom";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#B2BAC2",
    fontFamily: ["font-family: didot, serif"],
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#B2BAC2",
    },
    "&:hover fieldset": {
      borderColor: "#B2BAC2",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#B2BAC2",
    },
  },
});

const fontFormat = {
  width: "75%",
  // alignItems: "center",
  // direction: "column",
  display: "relative",
  // textAlign: "center",
};

//create a DefaultValue page and define default value for the drop down selections. use props in this page. Reference Local Harvest

// RSVP Notes:
// Use the "Name" text box entry and run through the backend "rsvp_check" function.
// The backend will return a list of people in the party along with their responses (ceremony/reception and after-party)
// Format the return list in a aesthetic table (with no borders)
// Return a error message if the name inputted does not exist in the database
// Allow the user to use drop down functions to update their RSVP response.
// Send this RSVP response data to the backend database when they click "Submit" button.
// Set up a pop-up that "the RSVP has not been sent" if the user exits the rsvp page.

export default function CustomizedInputsStyled() {
  const [fullName, setFullName] = useState();
  const [party, setParty] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // create a const for error message
  const [isError, setError] = useState(false);
  const [isOrderPlaced, setIsOrderPlaced] = useState("NotPlaced");

  async function get_party(nameParam) {
    var request = {
      requestType: "getParty",
      fullName: nameParam,
    };
    try {
      setIsLoading(true);
      const restOperation = post({
        apiName: "backend",
        path: "/rsvp",
        options: {
          body: request,
        },
      });
      const { body } = await restOperation.response;
      const party_json = await body.json();
      console.log("POST call succeeded");
      console.log("initial party list:", party_json);
      if ("error" in party_json) {
        console.log("Error!");
        // set the error message function into this if statement.
        setError(true);
        setParty([]);
      } else {
        setParty(party_json);
        setError(false);
      }
      //setError to false if the setIsLoading is running.
      if (setIsLoading(true)) {
        setError(false);
      }
      setIsLoading(false);
      return party_json;
    } catch (e) {
      console.log("POST call failed: ", e);
      setIsLoading(false);
    }
  }

  let errorMessage;
  if (isError) {
    errorMessage = (
      <Alert
        severity="error"
        sx={{
          // padding: "0px",
          // margin: "0px",
          fontSize: "12px",

          borderRadius: "0px",
        }}
      >
        Name not found. Please check your spelling or reach out to Grace or
        Douglas if you are encountering issues. Thanks!
      </Alert>
    );
  }

  let rsvpForm;
  if (party && party.length > 0) {
    let partyUniqueString = "";
    party.forEach((person) => {
      partyUniqueString += person.fullName;
    });
    rsvpForm = (
      <RsvpForm
        key={partyUniqueString}
        party={party}
        setIsOrderPlaced={setIsOrderPlaced}
        setParty={setParty}
      />
    );
  }

  let loadingComponent;
  if (isLoading) {
    loadingComponent = (
      <Box sx={{ display: "relative" }}>
        <CircularProgress sx={"text-align: center"} />
      </Box>
    );
  }
  const navigate = useNavigate();
  if (isOrderPlaced === "PlacedSuccessfully") {
    navigate("/success", {
      state: { party },
    });
  } else if (isOrderPlaced === "Error") {
    return (
      <div>
        <Typography variant="h3" gutterBottom>
          There was an error when RSVPing. Please reach out to Grace or Douglas
          to RSVP for your party. Thank you!
        </Typography>
      </div>
    );
  }

  return (
    <div className="Travel">
      <Header />
      <NavBar />
      <img src={TestPhoto} className="rsvp_picture" width="50%" />
      {/* <h1 className="Title-style">RSVP</h1>
      <img src={Divider} width="20%" /> */}
      <h1 className="h1">
        <p className="rsvp_text">Please RSVP by Saturday, August 17th</p>
        <br></br>
        <h2 className="h2">
          {" "}
          please submit your first and last name to RSVP for you and your party:
        </h2>
        <h1 className="box_h1">
          <Box className="h3">
            <CssTextField
              label="Name"
              id="rsvp-name-input"
              sx={{
                fontFamily: "didot, serif",
                fontSize: "calc(3px + 1.7vmin)",
              }}
              //onChange meaning every letter typed/removed will trigger this.
              onChange={(event) => {
                setFullName(event.target.value);
                console.log(
                  "Name check on Change in Check Party text box:",
                  event.target.value
                );
              }}
            />
          </Box>
          <Button
            type="submit"
            variant="outlined"
            className="button"
            size="small"
            sx={{
              textAlign: "center",
              fontFamily: "didot, serif",
              fontSize: "calc(3px + 1.7vmin)",
            }}
            onClick={() => {
              if (fullName) {
                get_party(fullName);
                console.log("onClick Submit button check:", fullName);
              }
            }}
          >
            Find Your Party
          </Button>
          <h2 className="loading_h2">{loadingComponent}</h2>
          <h2 className="error_h2">{errorMessage}</h2>
        </h1>
        {rsvpForm}
        <br></br>
      </h1>

      <Footer />
    </div>
  );
}
