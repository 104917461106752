import "./Photos.css";
import NavBar from "../components/NavBar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Divider from "../images/Divider.png";
import W1 from "../images/grace+douglas-6_websize.jpg";
import W2 from "../images/Engagement Shoot/grace+douglas-20.jpg";
import W3 from "../images/Engagement Shoot/grace+douglas-25.jpg";
import W5 from "../images/Engagement Shoot/grace+douglas-57.jpg";
import W6 from "../images/Engagement Shoot/grace+douglas-166_L.jpg";
import W8 from "../images/Engagement Shoot/grace+douglas-98.jpg";
import W9 from "../images/Engagement Shoot/grace+douglas-103_L.jpg";
import W10 from "../images/Engagement Shoot/grace+douglas-112.jpg";
import W11 from "../images/Engagement Shoot/grace+douglas-152.jpg";
import W12 from "../images/Engagement Shoot/grace+douglas-157.jpg";
import W16 from "../images/Engagement Shoot/grace+douglas-191.jpg";
import W17 from "../images/Engagement Shoot/grace+douglas-196.jpg";
import W18 from "../images/Engagement Shoot/grace+douglas-203.jpg";
import W19 from "../images/Engagement Shoot/grace+douglas-209.jpg";
import W20 from "../images/Engagement Shoot/grace+douglas-223.jpg";
import W22 from "../images/Engagement Shoot/grace+douglas-235_L.jpg";
import W23 from "../images/Engagement Shoot/grace+douglas-238_L.jpg";
import W24 from "../images/Engagement Shoot/grace+douglas-245.jpg";
import P1 from "../images/IMG_0043.jpg";
import P2 from "../images/IMG_0171.jpg";
import P3 from "../images/IMG_0196.jpg";
import P4 from "../images/Zion2.jpg";
import P5 from "../images/Zion1.jpg";
import P6 from "../images/Zion3.jpg";

import TestPhoto2 from "../images/Wedding Website/AaronUrsulaWed.jpg";
import TestPhoto3 from "../images/Wedding Website/AnniversaryDinnerSelfie.jpg";
import TestPhoto4 from "../images/Wedding Website/parklakehouse.jpg";
import TestPhoto100 from "../images/Wedding Website/parklakehouse.jpg";
import TestPhoto5 from "../images/Wedding Website/HongKongLandscape.jpg";
import TestPhoto6 from "../images/Wedding Website/HudsonYardLandscape.jpg";

function Photos() {
  return (
    <div className="OurStory">
      <Header />
      <NavBar />
      {/* <h1 className="Title-style">PHOTOS</h1>
      <img src={Divider} width="20%" /> */}
      <body className="body_style">
        <h1 className="Photo_text_h1">ENGAGEMENT PHOTOS</h1>
        <h1>
          <img src={W1} width="30%" className="Photo-h2" loading="eager" />
          <img src={W2} width="30%" className="Photo-h2" loading="eager" />
          <img src={W8} width="30%" className="Photo-h2" loading="eager" />
          <br></br>
          <img src={W5} width="30%" className="Photo-h2" loading="eager" />
          <img src={W10} width="30%" className="Photo-h2" loading="eager" />
          <img src={W3} width="30%" className="Photo-h2" loading="eager" />

          <br></br>
          <img src={W9} width="45%" className="Photo-h2" loading="eager" />
          <img src={W6} width="45%" className="Photo-h2" loading="eager" />
          <br></br>
          <img src={W12} width="30%" className="Photo-h2" loading="eager" />
          <img src={W16} width="30%" className="Photo-h2" loading="eager" />
          <img src={W19} width="30%" className="Photo-h2" loading="eager" />
          <br></br>
          <img src={W20} width="31.1%" className="Photo-h2" loading="eager" />
          <img src={W17} width="31.1%" className="Photo-h2" loading="eager" />
          <img src={W24} width="27.6%" className="Photo-h2" loading="eager" />
        </h1>
        <h1 className="Photo_text2_h1">THE PROPOSAL</h1>
        <h1>
          <img src={P1} width="32.7%" className="Photo-h2" />
          <img src={P3} width="30.7%" className="Photo-h2" />
          <img src={P2} width="28%" className="Photo-h2" />

          <br></br>
          <img src={P5} width="45%" className="Photo-h2" />
          <img src={P4} width="45%" className="Photo-h2" />
        </h1>
      </body>
      <br></br>
      <Footer />
    </div>
  );
}

export default Photos;
