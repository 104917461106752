import "./Timeline.css";
import NavBar from "../components/NavBar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import TestPhoto from "../images/grace+douglas-4.jpg";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Divider from "../images/Divider.png";
import HomeIcon from "@mui/icons-material/Home";

const fontFormat = {
  fontFamily: "didot, serif",
  color: "#6082b6",
  fontWeight: "bold",
  fontSize: "calc(1.5px + 1.9vmin)",
};

export default function CustomizedTimeline() {
  return (
    <div className="Timeline">
      <Header />
      <NavBar />
      {/* <h1 className="Title-style">TIMELINE</h1>
      <img src={Divider} width="20%" /> */}
      <body></body>
      <body>
        <img src={TestPhoto} className="Timeline-photo" />
      </body>
      <h1 className="TimelineHeader">
        The ceremony will start promptly at 5:00 PM. Please plan to arrive at
        4:45 PM the latest.
        <br></br>
        The below timeline is not finalized but this is an approximate time when
        everything will start. We will update the timeline closer to the wedding
        date.
      </h1>
      {/* <AccessTimeIcon sx={{ fontSize: 30 }} /> */}
      <h1 className="Timeline_override">
        <Timeline
          sx={{
            [`& .${timelineOppositeContentClasses.root}`]: {
              // flex: 0.5,
            },
          }}
        >
          <TimelineItem>
            <TimelineOppositeContent sx={fontFormat}>
              5:00 PM
            </TimelineOppositeContent>
            <TimelineSeparator
              sx={{
                height: 150,
              }}
            >
              <TimelineDot />
              {/* <TimelineConnector /> */}
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={fontFormat}>
              CEREMONY<br></br>- Poplar Springs Manor<br></br>- Outdoors
              (weather permitting)
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineOppositeContent sx={fontFormat}>
              5:30 PM
            </TimelineOppositeContent>
            <TimelineSeparator
              sx={{
                height: 150,
              }}
            >
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={fontFormat}>
              COCKTAIL HOUR<br></br>- Poplar Springs Manor<br></br>- Outdoors
              (weather permitting)
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineOppositeContent sx={fontFormat}>
              6:30 PM
            </TimelineOppositeContent>
            <TimelineSeparator
              sx={{
                height: 150,
              }}
            >
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={fontFormat}>
              RECEPTION<br></br>- Poplar Springs Manor<br></br>- Indoors
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineOppositeContent sx={fontFormat}>
              11:00 PM
            </TimelineOppositeContent>
            <TimelineSeparator
              sx={{
                height: 150,
              }}
            >
              <TimelineDot />
            </TimelineSeparator>
            <TimelineContent sx={fontFormat}>
              AFTER PARTY<br></br>- Poplar Springs Inn<br></br>- Outdoors &
              Indoors
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      </h1>
      <Footer />
    </div>
  );
}

// export default Timeline;
