import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import "../pages/Rsvp.css";
import { post } from "aws-amplify/api";
import FormHelperText from "@mui/material/FormHelperText";
import Typography from "@mui/material/Typography";
import {
  FormContainer,
  SelectElement,
  TextFieldElement,
} from "react-hook-form-mui";
import zip from "lodash/zip";
import { useForm } from "react-hook-form";
import RsvpSuccess from "../pages/RsvpSuccess";
import { useState } from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

function RsvpForm({ party, setParty, setIsOrderPlaced }) {
  const [isLoading, setIsLoading] = useState(false);

  let rsvpMessage;
  let submitButton;
  let partyTable;
  let currentWeddingRsvp = [];
  let currentAfterPartyRsvp = [];
  let currentDietaryNotes = [];

  let uniqueKeyString = "";

  let loadingComponent;
  if (isLoading) {
    loadingComponent = (
      <Box sx={{ display: "relative" }}>
        <CircularProgress sx={"text-align: center"} />
      </Box>
    );
  }

  if (party && party.length > 0) {
    uniqueKeyString += party[0].fullName;
    submitButton = (
      <Button
        type={"submit"}
        variant="outlined"
        className="button"
        size="small"
      >
        Submit RSVP
      </Button>
    );
    rsvpMessage = (
      <Typography
        pt={2}
        sx={{
          color: "#6082b6",
          fontFamily: "didot, serif",
          fontSize: "calc(3px + 1.5vmin)",
          fontStyle: "italic",
          fontWeight: "bold",
        }}
      >
        Please RSVP for your party below. Click "SUBMIT" at the bottom to
        confirm your reservation. <br></br>
        <br></br> Wedding: September 21, 2024 at 5:00 PM <br></br>After-party:
        September 21, 2024 at 11:00 PM
      </Typography>
    );
    partyTable = [];

    party.forEach((partyMember, index) => {
      const pastResponseWedding = partyMember ? partyMember.weddingRsvp : "";
      console.log("each member in [party table] loop: ", partyMember);
      console.log("test:", partyMember.weddingRsvp);
      currentWeddingRsvp.push(
        partyMember.weddingRsvp ? partyMember.weddingRsvp : ""
      );
      currentAfterPartyRsvp.push(
        partyMember.afterPartyRsvp ? partyMember.afterPartyRsvp : ""
      );
      currentDietaryNotes.push(
        partyMember.dietaryRestrictions ? partyMember.dietaryRestrictions : ""
      );
      partyTable.push(
        // create a stack for the returned party.
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          fontSize="calc(3px + 2.8vmin)"
        >
          {partyMember.fullName}
          <FormHelperText
            sx={{
              fontFamily: "didot, serif",
              fontSize: "calc(3px + 1.5vmin)",
              fontWeight: "bold",
            }}
          >
            Will this guest attend the Wedding and Ceremony?
          </FormHelperText>
          <SelectElement
            key={partyMember.fullName + "attendWedding"}
            required
            label=""
            name={`attendWedding[${index}]`}
            options={[
              {
                id: "Can Attend",
                label: "Can Attend",
              },
              {
                id: "Regretfully Decline",
                label: "Regretfully Decline",
              },
            ]}
            SelectProps={{
              sx: {
                width: "50%",
                minWidth: "200px",
                alignItems: "center",
                fontFamily: "didot, serif",
                fontSize: "calc(3px + 1.5vmin)",
              },
            }}
          />
          <FormHelperText
            sx={{
              fontFamily: "didot, serif",
              fontSize: "calc(3px + 1.5vmin)",
              fontWeight: "bold",
            }}
          >
            Will this guest attend the After Party?
          </FormHelperText>
          {/* connect this to backend spreadsheet. If initially empty, it should show empty frontend. */}
          <SelectElement
            required
            key={partyMember.fullName + "attendAfterParty"}
            label=""
            name={`attendAfterParty[${index}]`}
            options={[
              {
                id: "Can Attend",
                label: "Can Attend",
              },
              {
                id: "Regretfully Decline",
                label: "Regretfully Decline",
              },
            ]}
            SelectProps={{
              sx: {
                width: "50%",
                minWidth: "200px",
                alignItems: "center",
                fontFamily: "didot, serif",
                fontSize: "calc(3px + 1.5vmin)",
              },
            }}
          />
          <FormHelperText
            sx={{
              fontFamily: "didot, serif",
              fontSize: "calc(3px + 1.5vmin)",
              fontWeight: "bold",
            }}
          >
            Does this guest have any dietary restrictions?
          </FormHelperText>
          <TextFieldElement
            key={partyMember.fullName + "dietaryNotes"}
            label=""
            name={`dietaryNotes[${index}]`}
            sx={{
              width: "50%",
            }}
          />
        </Stack>
      );
    });
  }

  console.log(
    "Rob, checking:",
    currentWeddingRsvp,
    currentAfterPartyRsvp,
    currentDietaryNotes
  );

  return (
    <FormContainer
      key={uniqueKeyString}
      defaultValues={{
        attendWedding: currentWeddingRsvp,
        attendAfterParty: currentAfterPartyRsvp,
        dietaryNotes: currentDietaryNotes,
      }}
      onSuccess={(data) => {
        console.log("onSuccess data row:", data);
        let newParty = [...party];
        zip(
          newParty,
          data.attendWedding,
          data.attendAfterParty,
          data.dietaryNotes
        ).forEach((partyData) => {
          console.log("individual party data on Success:", partyData);
          partyData[0]["weddingRsvp"] = partyData[1];
          partyData[0]["afterPartyRsvp"] = partyData[2];
          partyData[0]["dietaryRestrictions"] = partyData[3];
        });
        setParty(newParty);
        console.log("multiple array party data on Success:", party);
        var orderWriteRequest = {
          requestType: "submitRsvp",
          party: party,
        };
        console.log(
          "JSON orderWriteRequest string:",
          JSON.stringify(orderWriteRequest)
        );
        sendRsvp(orderWriteRequest, party, setIsOrderPlaced);
        // TODO - return a submit successful page (with RSVP responses), and an error page if an error is encountered.

        // TODO - call backend with party variable as request parameter
        async function sendRsvp(request, party, setIsOrderPlaced) {
          try {
            setIsLoading(true);
            const restOperation = post({
              apiName: "backend",
              path: "/rsvp",
              options: {
                body: request,
              },
            });
            await restOperation.response;
            console.log("POST call succeeded");
            console.log("submit to backend:", party);
            setIsOrderPlaced("PlacedSuccessfully");
          } catch (e) {
            console.log("POST call failed: ", e);

            setIsOrderPlaced("Error");
          }
        }
      }}
    >
      {rsvpMessage}
      <h2 className="table_h2">{partyTable}</h2>
      {submitButton}
      <br></br>
      {loadingComponent}
    </FormContainer>
  );
}

export default RsvpForm;
