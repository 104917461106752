import "./OurStory.css";
import NavBar from "../components/NavBar";
import Header from "../components/Header";
import Footer from "../components/Footer";

import TestPhoto1 from "../images/Zion1.jpg";
import Divider from "../images/Divider.png";
import TestPhoto2 from "../images/Wedding Website/EasterBunny.jpg";
import TestPhoto4 from "../images/Wedding Website/JapanCherryBlossom.jpg";
import TestPhoto5 from "../images/Wedding Website/TanakaFarmHelloKitty.jpg";
import TestPhoto6 from "../images/Wedding Website/PokiKitten.jpg";
import TestPhoto7 from "../images/Wedding Website/DCCherryBlossom.jpg";
import TestPhoto8 from "../images/Wedding Website/JapanPiggyBack.jpg";
import TestPhoto9 from "../images/Wedding Website/YeriHiking.jpg";
import TestPhoto3 from "../images/Wedding Website/Katies23.jpg";

function OurStory() {
  return (
    <div className="OurStory">
      <Header />
      <NavBar />
      {/* <h1 className="Title-style">OUR STORY</h1>
      <img src={Divider} width="20%" /> */}
      <body className="Story_body">
        <h1 className="Story_h1">OUR STORY</h1>
        <h2 className="Story_h2">
          <p>
            Grace and Douglas met at University of Maryland, College Park (UMD)
            in Spring 2017 at an event called "Art Attack", a UMD event to mark
            the end of the school year. Grace celebrated graduating this year
            with her close friends and Douglas celebrated nearing the end of his
            co-op at Johnson & Johnson, so it was a momentous celebration for
            them both. Although they were different years, they still connected
            really well with their fun energy and love for drinking games at the
            pre-game. However, they left that event as just friends but
            reconnected every now and then with friends the following year.
          </p>
          <br></br>
          <p>
            In 2018, Grace and Douglas crossed paths again at a friend's
            birthday party and realized they had a lot of common hobbies such as
            hiking, attending shows, and enjoying all the good food in the area.
            Grace and Douglas went hiking together afterwards where they
            realized that they clicked really well with each other. They went on
            their first date at Matchbox and that's where their relationship
            started!
          </p>
          <br></br>
          <p>
            The next 3 years were filled with traveling, eating, and discovering
            new hobbies with one another. They traveled to Japan, Hong Kong,
            Shanghai, Los Angeles, Seattle, and many more destinations. They
            fell in love with Japan and its incredible culture that they are
            going back for their Honeymoon! Their favorite restaurants that they
            discovered in the area are Hulu Skewer House, Honest Grill, Meat
            Project, Bob's Shanghai, and Pho 75.
          </p>
          <br></br>
          <p>
            Because the pandemic limited in-person interactions, Grace learned
            to play video games which Douglas did an amazing job introducing her
            to. Her and Douglas played games like League of Legends, Dead by
            Deadlight, and many more where they virtually connected with friends
            all over the country. Grace learned how to support her friends in
            the game and Douglas learned extreme patience while teaching Grace
            how to play. It took Grace a long time to learn but they now play
            with their friends frequently!<br></br>
            <br></br> During the pandemic, they also adopted their daughter,
            Poki, the socially awkward yet adorable kitten that they fell in
            love with at first sight!
          </p>
          <br></br>
          <p>
            Grace planned a birthday trip to tour the National Parks in the
            Southwest region of the US with our closest friends. They went to
            Antelope Canyon/Horseshoe Bend, Bryce Canyon, and Zion. Little did
            Grace know, Douglas had other plans in mind for the trip. Grace's
            friends planned a sunrise trip right outside Zion National Park.
            Douglas opted out of this trip and wanted to "catch up on some
            sleep". When Grace and her friends arrived at the location, Douglas
            was surprisingly there with a beautiful bouquet of flowers and the
            sunrise behind him. Douglas got down on one knee and proposed to
            Grace in front of the beautiful red rocks and sunrise. Grace said
            YES!
          </p>
        </h2>

        <img
          src={TestPhoto3}
          className="Story_photo_portrait"
          alt="image cannot load"
          title="When we rekindled in 2018!"
        />
        <img
          src={TestPhoto9}
          className="Story_photo_portrait"
          title="Our first hike together"
        />
        <br></br>
        <img
          src={TestPhoto5}
          className="Story_photo_landscape"
          title="Irvine 2019"
        />
        <br></br>
        <img
          src={TestPhoto8}
          className="Story_photo_portrait"
          title="Japan 2019"
        />
        <img
          src={TestPhoto7}
          className="Story_photo_portrait"
          title="Irvine 2019"
        />
        <br></br>

        <img
          src={TestPhoto6}
          className="Story_photo_landscape"
          title="Poki Adoption"
        />
        <br></br>
        <img
          src={TestPhoto2}
          className="Story_photo_landscape"
          title="Art Attack 2017"
        />
        <br></br>
        <img
          src={TestPhoto1}
          className="Story_photo_landscape"
          title="Zion 2022"
        />
      </body>

      <Footer />
    </div>
  );
}

export default OurStory;
